/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Global */
.marginAuto {
  margin: auto !important;
}

.marginNone {
  margin: 0px !important;
}

.notLoggedIn {
  --background: none !important;
  background: none !important;
  .container-fluid {
    background-color: var(--greyLight);
    .sideImg {
      height: 100vh;
      margin: 0px;
      padding: 0px;
      ion-img {
        height: 100%;
      }
    }
    .sideInfo {
      margin: 0px;
      padding: 0px;
      .container {
        height: 100vh;
        position: relative;
        .row {
          margin: 0;
          position: absolute;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
      a {
        color: var(--blueDark);
        padding-top: 20px !important;
        float: right;
      }
    }
  }
}

ion-content {
  --background: url('../src/assets/backgroundLogo1.png') 0px bottom/200px 200px no-repeat;
  background: url('../src/assets/backgroundLogo2.png') right 80px/270px 140px no-repeat;
  #marginTop {
    margin-top: 80px !important;
  }
  .col {
    margin: 20px 10px;
  }
}

h1, h2 {
  color: var(--grey) !important;
  color: #333333 !important;
  font-family: "Open Sans", sans-serif;
}

h1 {
  font-size: 32px;
  padding-top: 16px;
  line-height: 100%;
}

h2 {
  font-size: 20px;
  font-weight: 500;
}

.btn {
  --background: var(--orangeLight);
  background: var(--orangeLight);
  color: white;
  text-transform: lowercase;
  padding: 0px;
  border: 2px solid var(--orangeDark);
  ion-icon {
    padding-right: 10px;
  }
}

.btnAddPackages {
  color: #000;
  --background: transparent;
  --box-shadow: transparent;
  float: right;
  margin: 0;
  padding: 0;
  margin-top: -5px;
}

.btnEditPackages {
  --background: transparent;
  --box-shadow: transparent;
  color: #000;
  width: 45px;
  margin: 0;
  padding: 0;
  margin-top: -10px;
}

.primary {
  width: 100%;
}

.save {
  float: right;
}

input::placeholder, textarea::placeholder {
  color: var(--greyDark) !important;
}

.table {
  thead {
    background: var(--orangeLight);
    color: white;
    font-size: 12px;
  }
  tbody {
    font-size: 12px;
    i {
      font-size: 20px;
      padding-left: 20px;
    }
  }
}

.tabCollapseHead {
  background: var(--greyLight);
  color: #000000;
  border-radius: 25px 25px 0px 0px;
  margin-top: 0px;
  ion-icon {
    float: right;
    font-size: 25px;
  }
  padding-bottom:10px;
}

.tabCollapseHead .col {
  margin-bottom:0px;
}

.tabCollapseBody {
  background: var(--greyLight);
  margin-bottom:10px;
}

.loading-wrapper.sc-ion-loading-md {
  background:#FFFFFF;
}
select {
  width: 100%;
  padding: 10px;
  color: var(--grey);
}

.container-fluid {
  max-width:1200px;
}

header.container-fluid {
  max-width: none;
}

.btn {
  --box-shadow: none;
  border:0 ! important;
}

.col.no-margin {
  margin-top:0;
  margin-bottom:0;
}
.loading-wrapper.sc-ion-loading-md {
  background:#FFFFFF;
}

.map {
  height: 708px;
  width: 100%;
  background:#EFEFEF;
  position: sticky !important;
  top: 100px;
}

.action-button {
  color:#000000;
  cursor: pointer;
}

.action-button:hover {
  display:inline-block;
  color: #00625e;
}

.btn.btn-text {
  background:#FFFFFF ! important;
  --background:#FFFFFF ! important;
  color:#333333 ! important;
}

select {
  width: 100%;
  padding: 10px;
  color: #333333;
  background: #FFF;
  border: 1px solid #CECCCC;
  padding-top: 8px;
  padding-bottom: 6px;
  border-radius: 3px;
}

.profileLetter {
  position: relative;
  height: 50px;
  background: #FFF;
  display: inline-block;
  width: 50px;
  border-radius: 50%;
  line-height: 50px;
  font-weight: bold;
}

.profileLetter > strong {
  position:absolute;
  line-height:50px;
  width:100%;
  height:100%;
  font-size:32px;
  font-weight:bold;
  z-index:1;
  text-align:center;
  color:#00625e;
  left:0;
  display:block;
}

#profileName {
  position:relative;
  top:-20px;
}

.ion-page {
  background:#FFFFFF;
}

ion-item.checkbox {
  background:transparent;
  --background:transparent;
  border:0;
  --border:0;
  --border-color:transparent;
  margin-left:-15px;
  --min-height: 0;
}

ion-item.checkbox ion-label {
  font-size:80% ! important;
}

html, body {
  scroll-behavior: smooth;
}

.searchContent {
  float: right;
  margin-top: 20px;
  padding-right: 0px;
}

.mainProfileImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 5px;
}
